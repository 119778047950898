import React from 'react';
import createStyles from '@material-ui/styles/createStyles';
import {withStyles} from '@material-ui/styles';
import {Button as MUIButton} from '@material-ui/core';

const styles = createStyles({
    root: {},
    containedPrimary: {
        height: 42,
        width: 147,
        backgroundColor: 'rgb(255,255,255)',
        borderRadius: 25,
        boxShadow: 'none',
    },
    label: {
        color: 'rgb(16,11,87)',
        textTransform: 'none',
        fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        fontSize: 14,
    },
});

interface ButtonProps {
    text: string;
    classes: any;
    disabled?: boolean;
    className?: string;
    onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({text, onClick: handleClick, classes, ...props}) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        handleClick();
        event.currentTarget.blur();
    };

    return (
        <MUIButton variant="contained" color="primary" classes={classes} onClick={onClick} {...props}>
            {text}
        </MUIButton>
    );
};

export default withStyles(styles)(Button);
