import React, {useState} from 'react';
import createStyles from '@material-ui/styles/createStyles';
import {makeStyles} from '@material-ui/styles';
import {InputAdornment, Input} from '@material-ui/core';
import Button from './Button';
interface PasswordInputProps {
    error: boolean;
    onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            width: 311,
            height: 53,
            borderRadius: 15,
            backgroundColor: '#FFFFFF',
            border: (props: PasswordInputProps) => (props.error ? '2px' : '0px'),
            borderStyle: (props: PasswordInputProps) => (props.error ? 'solid' : 'none'),
            borderColor: (props: PasswordInputProps) => (props.error ? 'rgb(236,107,87)' : 'initial'),
            fontSize: 16,
            paddingLeft: 8,
            paddingTop: 0,
            paddingRight: 11,
            paddingBottom: 0,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            textTransform: 'none',
            color: 'rgb(16,11,87)',
        },
        button: {
            backgroundColor: 'rgb(16,11,87)',
            width: 57,
            height: 32,
        },
        buttonText: {
            color: '#FFFFFF',
            textTransform: 'none',
            fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: 11,
        },
    }),
);

const PasswordInput: React.FC<PasswordInputProps> = ({onChange, ...props}) => {
    const styles = useStyles(props);
    const [showPassword, setShowPassword] = useState(false);
    const [hasPasswordInput, setHasPasswordInput] = useState(false);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        const status = value ? true : false;
        setHasPasswordInput(status);
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <Input
            placeholder={'Password'}
            type={showPassword ? 'text' : 'password'}
            disableUnderline={true}
            onChange={handleInputChange}
            error={true}
            endAdornment={
                <InputAdornment position="end">
                    <Button
                        classes={{
                            containedPrimary: styles.button,
                            label: styles.buttonText,
                        }}
                        disabled={!hasPasswordInput}
                        text={showPassword ? 'Hide' : 'Show'}
                        onClick={() => {
                            setShowPassword(!showPassword);
                        }}
                    />
                </InputAdornment>
            }
            className={styles.root}
            {...props}
        />
    );
};

export default PasswordInput;
