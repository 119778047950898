import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {
    AccessToken,
    ApiResposne,
    LoginResponse,
    RegistrationRequest,
    RegistrationResponse,
    RequestType,
    ValidateVoucherCodeRequest,
    ValidateVoucherCodeResponse,
    ResetPasswordRequest,
} from './types';
import Analytics from '../AnalyticsHelper';
const serviceUrls = {
    idp: process.env.REACT_APP_AUTHORITY,
};

const BearerTokenPrefix = 'Bearer ';
type ServiceType = 'idp';
const AuthHeader = 'Authorization';
const grantTypes = {
    [RequestType.GetClientAccessToken]: 'client_credentials',
    [RequestType.Login]: 'password',
    [RequestType.RefreshToken]: 'refresh_token',
};

const endpoints = {
    exerciseList: 'user/me/task/exercise.completion',
    profile: 'user/me',
    registration: 'api/registration',
    resetPassword: 'api/registration/password/reset',
    setNewPassword: 'api/registration/password',
    task: 'user/me/task',
    token: 'connect/token',
    video: 'videos',
    voucherCode: 'api/code',
    worryMapList: 'user/me/task/worrymap.questionnaire',
};
const clientConfig = {
    clientId: 'mentemia.registration',
    clientSecrete: 'Mzg5MTI4OXVkc2prYXNka2piZmFqc2tiMTIwOTN1MTk4amthZHNiZmthamJzZ2ZrbmFiMjAz',
    redirectUrl: 'com.mentemia.companionapp:/oauthredirect',
    scopes: ['openid', 'profile', 'offline_access', 'orgId', 'status', 'registration'],
    timeout: 20000,
};

type AxiosErrorInterceptor = (error: any) => Promise<any>;
type AxiosRequestInterceptor = (requestConfig: AxiosRequestConfig) => Promise<AxiosRequestConfig>;

export class MentemiaService {
    static getInstance() {
        if (!MentemiaService.instance) {
            MentemiaService.instance = new MentemiaService();
        }
        return MentemiaService.instance;
    }

    private static instance: MentemiaService;
    private refreshTokenUpdateCallback: ((refreshToken: string) => void) | undefined;
    private accessToken: AccessToken;

    private constructor() {
        this.accessToken = AccessToken.init();
    }

    clearAccessToken = () => {
        this.setAccessToken('', 0, '');
    };

    registerUser = async (request: RegistrationRequest): Promise<RegistrationResponse> => {
        try {
            const data = {
                Code: request.code,
                ConfirmPassword: request.password,
                Email: request.username,
                Password: request.password,
            };
            const result = await this.getApiClient('idp').post(endpoints.registration + '/' + request.orgId, data);
            return {
                refreshToken: result.data.refresh_token,
                status: result.status,
                statusText: result.statusText,
            };
        } catch (error) {
            return this.handleErrorResposne('RegisterUser', error);
        }
    };

    validateVoucherCode = async (request: ValidateVoucherCodeRequest): Promise<ValidateVoucherCodeResponse> => {
        try {
            const result = await this.getApiClient('idp').get(endpoints.voucherCode + '/' + request.voucherCode);
            return {
                code: result.data.code,
                expiry: result.data.expiry,
                orgId: result.data.orgId,
                status: result.status,
                statusText: result.statusText,
            };
        } catch (error) {
            return this.handleErrorResposne('ValidateVoucherCode', error);
        }
    };

    private setAccessToken = (accessToken: string, accessTokenExpiry: number, refreshToken: string = '') => {
        this.accessToken.value = accessToken;
        this.accessToken.expiry = accessTokenExpiry;
        this.accessToken.refreshToken = refreshToken;
        this.accessToken.timeCreated = new Date();
    };

    // renew the user access token with refresh token
    private refreshAccessToken = async (refreshToken: string = ''): Promise<LoginResponse> => {
        try {
            if (refreshToken === '') {
                refreshToken = this.accessToken.refreshToken;
            }
            const formData = this.buildAccesstokenFormData(RequestType.RefreshToken);
            formData.append('refresh_token', refreshToken);
            const response = await this.getApiClient('idp').post(endpoints.token, formData);

            const loginResponse = this.adaptToLoginResponse(response);
            this.setAccessToken(loginResponse.accessToken!, loginResponse.expiry!, loginResponse.refreshToken);
            return loginResponse;
        } catch (error) {
            return this.handleErrorResposne('RefreshAccessToken', error);
        }
    };

    private getClientAccessToken = async () => {
        try {
            const formData = this.buildAccesstokenFormData(RequestType.GetClientAccessToken);
            const result = await this.getApiClient('idp').post(endpoints.token, formData);
            return {
                accessToken: result.data.access_token,
                expiry: result.data.expires_in,
            };
        } catch (error) {
            return Promise.reject(error);
        }
    };

    private getApiClient = (serviceType: ServiceType = 'idp') => {
        const baseUrl = serviceUrls[serviceType];
        console.log(baseUrl);
        const client = axios.create({
            baseURL: baseUrl,
            timeout: clientConfig.timeout,
        });
        client.defaults.headers = {
            Authorization: BearerTokenPrefix + this.accessToken.value,
        };
        client.interceptors.request.use(this.createUnAuthRequestInterceptor());
        if (serviceType !== 'idp') {
            client.interceptors.response.use(undefined, this.createUnAuthErrorInterceptor(client));
        }
        return client;
    };

    private buildAccesstokenFormData = (requestType: RequestType): FormData => {
        const grantType = grantTypes[requestType];
        const scope = requestType === RequestType.GetClientAccessToken ? 'registration' : clientConfig.scopes.join(' ');
        const formData = new FormData();
        formData.append('client_id', clientConfig.clientId);
        formData.append('client_secret', clientConfig.clientSecrete);
        formData.append('grant_type', grantType);
        formData.append('scope', scope);
        return formData;
    };

    private createUnAuthErrorInterceptor = (client: AxiosInstance): AxiosErrorInterceptor => {
        return async (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                const idp: ServiceType = 'idp';
                if (error.config.baseUrl !== serviceUrls[idp]) {
                    const response = await this.refreshAccessToken();
                    this.setAccessToken(response.accessToken!, response.expiry!, response.refreshToken);
                    if (this.refreshTokenUpdateCallback) {
                        this.refreshTokenUpdateCallback(response.refreshToken!);
                    }
                    error.config.headers[AuthHeader] = BearerTokenPrefix + this.accessToken.value;
                    return client.request(error.config);
                }
            }
            return Promise.reject(error);
        };
    };

    private createUnAuthRequestInterceptor = (): AxiosRequestInterceptor => {
        return async (config: AxiosRequestConfig) => {
            try {
                if (!this.hasValidBearerToken(config) && config.url !== endpoints.token) {
                    const response = await this.getClientAccessToken();
                    this.setAccessToken(response.accessToken, response.expiry);
                    config.headers[AuthHeader] = BearerTokenPrefix + response.accessToken;
                    return config;
                }
            } catch (error) {
                return Promise.reject(error);
            }
            return config;
        };
    };

    private hasValidBearerToken = (config: AxiosRequestConfig): boolean => {
        if (config.headers == null) {
            return false;
        }
        if (config.headers[AuthHeader] == null) {
            return false;
        }
        const bearToken = config.headers[AuthHeader] as string;
        return bearToken.length > 8;
    };

    private adaptToLoginResponse = (result: AxiosResponse<any>): LoginResponse => {
        return {
            accessToken: result.data.access_token,
            expiry: result.data.expires_in,
            refreshToken: result.data.refresh_token,
            status: result.status,
            statusText: result.statusText,
        };
    };

    tryParseNumber = (str: any, defaultValue: number = 0): number => {
        console.log('Value to be parsed: ' + str);
        let retValue = defaultValue;
        if (str && !isNaN(str)) {
            console.log('Parsing: ' + str);
            retValue = parseInt(str, 10);
        }
        return retValue;
    };

    private handleErrorResposne = (context: string, error: AxiosError): ApiResposne => {
        let result: ApiResposne = {status: 0, statusText: ''};
        if (error.response) {
            result.status = error.response.status;
            result.statusText = error.response.statusText;
            if (error.response.data) {
                result.details = error.response.data.details;
                result.errorCode = this.tryParseNumber(error.response.data.error);
                result.errorMessage = error.response.data.error_description;
            }
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
        console.log(error.message);
        Analytics.trackEvent('APICallFailed', context, result.errorMessage ? result.errorMessage : result.statusText);
        return result;
    };

    setNewPassword = async (request: ResetPasswordRequest): Promise<LoginResponse> => {
        try {
            const result = await this.getApiClient('idp').post(endpoints.setNewPassword, {
                Code: request.code,
                ConfirmPassword: request.password,
                Email: request.username,
                Password: request.password,
            });
            const response = this.adaptToLoginResponse(result);
            if (response.status === 200) {
                this.setAccessToken(response.accessToken!, response.expiry!, response.refreshToken);
            }
            return response;
        } catch (error) {
            return this.handleErrorResposne('SetNewPassword', error);
        }
    };
}

export default MentemiaService;
