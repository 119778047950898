import React from 'react';
import {Grid, createStyles} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {contactus_data} from './StringConstants';
import Analytics from '../AnalyticsHelper';
import {colors} from '../theme-resetpassword';
const styles = createStyles({
    boldText: {
        fontWeight: 600,
        fontSize: 14,
        color: colors.blue,
        textDecoration: 'none',
    },
});

interface FooterProps {
    classes: {
        boldText: string;
    };
}

const supportUrl = contactus_data.contactus_url;

const CustomerServiceFooter: React.FC<FooterProps> = ({classes}) => {
    const handleClick = () => {
        Analytics.trackEvent('ContactSupportClicked');
    };
    return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            <Grid item>
                <a
                    className={classes.boldText}
                    onClick={handleClick}
                    href={supportUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    {contactus_data.customer_service_text}
                </a>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(CustomerServiceFooter);
