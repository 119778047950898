export interface ApiResposne {
    status: number;
    statusText: string;
    errorCode?: number;
    errorMessage?: string;
    details?: any;
}
export interface ApiRequest {}

export interface LoginRequest {
    username: string;
    password: string;
}

export interface LogoutRequest extends ApiRequest {}

export interface LoginResponse extends ApiResposne {
    accessToken?: string;
    expiry?: number;
    refreshToken?: string;
}

export interface ValidateVoucherCodeRequest extends ApiRequest {
    voucherCode: string;
}

export interface ValidateVoucherCodeResponse extends ApiResposne {
    orgId?: string;
    code?: string;
    expiry?: Date;
}

export interface RegistrationRequest extends ApiRequest {
    username: string;
    password: string;
    orgId: string;
    code: string;
}

export interface ResetPasswordRequest extends ApiRequest {
    username: string;
    password: string;
    code: string;
}

export interface RegistrationResponse extends ApiResposne {
    refreshToken?: string;
}

export enum RequestType {
    Login = 1,
    RefreshToken,
    GetClientAccessToken,
}

export class AccessToken {
    static init = (
        value: string = '',
        refreshToken: string = '',
        expiry: number = 0,
        timeCreated: Date = new Date(),
    ) => {
        return new AccessToken(value, refreshToken, expiry, timeCreated);
    };
    value: string;
    refreshToken: string;
    expiry: number;
    timeCreated: Date;
    constructor(value: string, refreshToken: string, expiry: number, timeCreated: Date) {
        this.value = value;
        this.expiry = expiry;
        this.refreshToken = refreshToken;
        this.timeCreated = timeCreated;
    }
}

export interface ResetPasswordRequest extends ApiRequest {
    username: string;
    password: string;
    code: string;
}
