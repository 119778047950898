import React, {useEffect, useContext} from 'react';
import {Typography, createStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Feedback from './Feedback';
import appStoreLogo from '../assets/image/app-store.png';
import googlePlayLogo from '../assets/image/google-play.png';
import AppContext from '../AppContext';
import {store_urls} from './StringConstants';
import Analytics from '../AnalyticsHelper';

const useStyles = makeStyles(
    createStyles({
        header: {
            marginBottom: '3%',
            width: 368,
            margin: '0 auto',
        },
        body: {
            marginBottom: '3%',
            width: 311,
            margin: '0 auto',
        },

        footer: {
            width: 311,
            margin: '0 auto',
            marginBottom: '3%',
            marginTop: '3%',
        },

        logo: {
            height: 44,
        },
        logoContainer: {
            width: 340,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 auto',
        },
        text: {
            fontSize: 16,
            lineHeight: 1.2,
        },

        downloadText: {
            fontSize: 18,
            fontFamily: ['Averta-Bold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
    }),
);

const Confirmation: React.FC = () => {
    const styles = useStyles();
    const appContext = useContext(AppContext);
    useEffect(() => {
        appContext.codeVerified = false;
        appContext.orgId = '';
        appContext.voucherCode = '';
    });

    const handleAppleStoreClick = () => {
        Analytics.trackEvent('AppStoreIconClicked');
    };
    const handleGooglePlayClick = () => {
        Analytics.trackEvent('GooglePlayIconClicked');
    };
    return (
        <div>
            <div className={styles.header}>
                <Typography variant="h1" align="center">
                    You’re all set up!
                </Typography>
            </div>
            <div className={styles.header}>
                <Feedback status="good" />
            </div>
            <div className={styles.body}>
                <Typography className={styles.text} variant="subtitle1" align="center">
                    {'Your account is all set up.'}
                </Typography>
                <Typography className={styles.text} variant="subtitle1" align="center">
                    {'Now install the app on your phone and sign in to begin the journey to enhanced mental wellbeing.'}
                </Typography>
            </div>
            <div className={styles.footer}>
                <Typography className={styles.downloadText} variant="subtitle1" align="center">
                    Download the Mentemia app free
                </Typography>
            </div>
            <div className={styles.logoContainer}>
                <div>
                    <a
                        href={store_urls.apple}
                        onClick={handleAppleStoreClick}
                        target="_blank"
                        rel="noopener noreferrer">
                        <img className={styles.logo} src={appStoreLogo} alt="AppStoreLogo" />
                    </a>
                </div>
                <div>
                    <a
                        href={store_urls.google}
                        onClick={handleGooglePlayClick}
                        target="_blank"
                        rel="noopener noreferrer">
                        <img className={styles.logo} src={googlePlayLogo} alt="GooglePlayLogo" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Confirmation;
