import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import {createStyles, Grid, makeStyles} from '@material-ui/core';
import Button from './Button';
import {withRouter, RouteComponentProps} from 'react-router';
import AppContext from '../AppContext';
import Header from './Header';
import Card from './Card';
import AppImage from '../assets/image/mentemia-app.png';
import Analytics from '../AnalyticsHelper';

const useStyles = makeStyles(
    createStyles({
        container: {
            width: 768,
            margin: 'auto',
        },
        item: {
            paddingTop: '15px',
            paddingBottom: '15px',
            textAlign: 'center',
        },
        itemLeftAligned: {
            paddingTop: '15px',
            paddingBottom: '15px',
            textAlign: 'left',
        },
        h1: {
            fontFamily: ['Averta-Extrabold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '2em',
            color: 'rgb(16, 11, 87)',
        },
        h2: {
            fontFamily: ['Averta-Bold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '2em',
            color: 'rgb(16, 11, 87)',
        },
        h3: {
            fontFamily: ['Averta-Bold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '1.3em',
            color: 'rgb(16, 11, 87)',
        },
        text: {
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '1.2em',
            color: 'rgb(16, 11, 87)',
        },
        boldText: {
            fontFamily: ['Averta-Bold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '1.1em',
            color: 'rgb(16, 11, 87)',
        },
        image: {
            width: 700,
        },
        containedPrimary: {
            height: 52,
            width: 201,
            backgroundColor: 'rgb(16, 11, 87)',
            borderRadius: 25,
            boxShadow: 'none',
        },
        label: {
            color: 'rgb(255,255,255)',
            textTransform: 'none',
            fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '1.2em',
        },
        focusVisible: {},
    }),
);

interface LandingPageProps extends RouteComponentProps {}

const LandingPage: React.FC<LandingPageProps> = ({history}) => {
    const appContext = useContext(AppContext);
    Analytics.init();
    const styles = useStyles();
    const onRegister = () => {
        Analytics.trackEvent('RegisterNowClicked');
        appContext.orgId = 'TWG';
        history.push('/register/twg');
    };

    return (
        <Grid container justify="center" alignItems="center" className={styles.container}>
            <Grid item xs={12} className={styles.item}>
                <Header blue />
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <Typography className={styles.text}>Welcome to Mentemia</Typography>
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <Typography className={styles.h1}>A special message from JK</Typography>
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <iframe
                    src="https://player.vimeo.com/video/343973468"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    title="JK's Video"
                />
            </Grid>
            <Grid item xs={12} className={styles.itemLeftAligned}>
                <Typography className={styles.text}>
                    Mental fitness is just as important as physical fitness. The Mentemia app will help you discover
                    simple ways to build up mental fitness so that you can overcome the stresses of daily life and feel
                    better, every day.
                </Typography>
            </Grid>

            <Grid item xs={12} className={styles.item}>
                <Button
                    classes={{
                        containedPrimary: styles.containedPrimary,
                        label: styles.label,
                    }}
                    text={'Register now'}
                    onClick={onRegister}
                />
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" className={styles.item}>
                <Grid item xs={4} className={styles.item}>
                    <Card
                        icon={'mood'}
                        title="Feel better"
                        body="Use our proven techniques to improve mood, calm your mind and thrive."
                    />
                </Grid>
                <Grid item xs={4} className={styles.item}>
                    <Card
                        icon={'stress'}
                        title="Release Worries"
                        body="Get a handle on what’s worrying you, and manage these worries with less stress."
                    />
                </Grid>
                <Grid item xs={4} className={styles.item}>
                    <Card
                        icon={'secure'}
                        title="100% secure"
                        body="Your personal information will never be shared, not with your employer, not anyone!"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <img className={styles.image} src={AppImage} alt="Mentemia App" />
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <Typography className={styles.text}>Join the private beta</Typography>
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <Typography className={styles.h1}>Available now for iOS & Android</Typography>
            </Grid>
            <Grid item xs={12} className={styles.itemLeftAligned}>
                <Typography className={styles.h2}>How does it work?</Typography>
            </Grid>

            <Grid item xs={12} className={styles.itemLeftAligned}>
                <Typography className={styles.text}>
                    Register <span className={styles.boldText}>free</span> for the Mentemia app and you'll have full and
                    <span className={styles.boldText}> free access</span> to the Mentemia app 24 hours a day/ 7 days a
                    week. Your employer is footing the bill.
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.itemLeftAligned}>
                <Typography className={styles.text}>
                    All you need is a mobile phone. You don't even need to use your work email to register, a private
                    email is just fine. No tricks, no gotchas. So what are you waiting for? Fire up the app and enjoy a
                    <span className={styles.boldText}> boost to your mental fitness</span> today.
                </Typography>
            </Grid>

            <Grid item xs={12} className={styles.item}>
                <Typography className={styles.h2}>Get immediate access</Typography>
            </Grid>

            <Grid item xs={12} className={styles.item}>
                <Button
                    classes={{
                        containedPrimary: styles.containedPrimary,
                        label: styles.label,
                    }}
                    text={'Register now'}
                    onClick={onRegister}
                />
            </Grid>
            <Grid item xs={12} className={styles.itemLeftAligned}>
                <Typography className={styles.text}>
                    Register for the app, get it on your phone and most importantly start using it! This is the very
                    first version of the app and we want you to shape it with us. Try out some of the techniques and let
                    us know how you get on. We can't wait to hear from you. JK (Sir John Kirwan) and the whole team at
                    Mentemia.
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.itemLeftAligned}>
                <Typography className={styles.h3}>JK (Sir John Kirwan) and the whole team at Mentemia.</Typography>
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <Header blue />
            </Grid>
        </Grid>
    );
};

export default withRouter(LandingPage);
