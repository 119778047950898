import React from "react";
import logo from "../assets/image/logo.png";
import logoBlue from "../assets/image/logo-blue.png";
import { createStyles, withStyles } from "@material-ui/core";

const styles = createStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

interface HeaderProps {
  blue?: boolean;
  classes: {
    root: string;
  };
}

const Header: React.FC<HeaderProps> = ({ classes, blue }) => {
  return (
    <div className={classes.root}>
      <img className="app-logo" src={blue ? logoBlue : logo} alt="Logo" />
    </div>
  );
};

export default withStyles(styles)(Header);
