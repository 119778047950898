import React from 'react';
import {createStyles, withStyles, Typography} from '@material-ui/core';
import Footer from './Footer';

const styles = createStyles({
    div: {
        marginBottom: '3%',
        width: '100%',
    },
    text: {
        fontSize: '2rem',
        color: '#FFFFFF',
        fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
    },
});

interface ErrorProps {
    classes: {
        div: string;
        text: string;
    };
}

const Home: React.FC<ErrorProps> = ({classes}) => {
    const renderFailureMessage = () => {
        return (
            <div className={classes.div}>
                <Typography className={classes.text} align="center">
                    The link is invalid or has expired.
                </Typography>
                <Footer />
            </div>
        );
    };
    return renderFailureMessage();
};

export default withStyles(styles)(Home);
