import React from 'react';
import {Typography, createStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import StressIcon from '../assets/image/stress.png';
import ModeIcon from '../assets/image/mood.png';
import SecureIcon from '../assets/image/secure.png';

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            justifyContent: 'flex-start',
            paddingRight: '8px',
        },

        icon: {
            height: '31px',
        },
        title: {
            fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '1.67em',
            color: 'rgb(16, 11, 87)',
        },
        body: {
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: '1.2em',
            color: 'rgb(16, 11, 87)',
        },
    }),
);

interface CardProps {
    icon: string;
    title: string;
    body: string;
}

const Card: React.FC<CardProps> = (props: CardProps) => {
    const styles = useStyles();
    const getIcon = (icon: string) => {
        switch (icon) {
            case 'stress':
                return StressIcon;
            case 'mood':
                return ModeIcon;
            case 'secure':
                return SecureIcon;
        }
        return StressIcon;
    };
    return (
        <div className={styles.container}>
            <div>
                <img className={styles.icon} src={getIcon(props.icon)} alt="icon" />
            </div>
            <div>
                <Typography className={styles.title}> {props.title}</Typography>
            </div>
            <div>
                <Typography className={styles.body}> {props.body}</Typography>
            </div>
        </div>
    );
};

export default Card;
