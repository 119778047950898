import React from "react";
import classNames from "classnames";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Checkbox from "./Checkbox";
import { terms_data } from "./StringConstants";
import Analytics from "../AnalyticsHelper";
const styles = createStyles({
  container: {
    marginTop: 32,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  checkbox: {
    width: 24,
    borderRadius: 24
  },
  textPosition: {
    marginLeft: 11
  },

  text: {
    fontSize: 13,
    color: "#FFFFFF"
  },
  link: {
    fontWeight: 600,
    textDecoration: "none",
    marginLeft: 4
  },
  error: {
    color: "rgb(236,107,87)"
  }
});

interface TermsProps {
  classes: {
    checkbox: string;
    container: string;
    text: string;
    textPosition: string;
    link: string;
    error: string;
  };
  onChange: (event: object, checked: boolean) => void;
  error?: boolean;
}

const Terms: React.FC<TermsProps> = ({ classes, ...props }) => {
  const textClasses = classNames({
    [classes.textPosition]: true,
    [classes.text]: true,
    [classes.error]: props.error
  });

  const linkClasses = classNames({
    [classes.text]: true,
    [classes.link]: true,
    [classes.error]: props.error
  });

  const handleClick = () => {
    Analytics.trackEvent("TermsOfUseClicked");
  };

  return (
    <div className={classes.container}>
      <Checkbox {...props} />
      <span className={textClasses}>
        {terms_data.terms_text}
        <a
          href={terms_data.terms_url}
          onClick={handleClick}
          target="_blank"
          className={linkClasses}
          rel="noopener noreferrer"
        >
          {terms_data.terms_link_text}
        </a>
      </span>
    </div>
  );
};

export default withStyles(styles)(Terms);
