import Countly from 'countly-sdk-web';

class AnalyticsHelper {
    private countly: any;
    constructor() {
        this.initCountly();
    }

    private initCountly = () => {
        console.log('Initialize countly!');
        this.countly = Countly || {};
        this.countly.q = Countly.q || [];

        this.countly.app_key = process.env.REACT_APP_ANALYTICS_API_KEY;
        this.countly.url = process.env.REACT_APP_ANALYTICS_API_URL;
        this.countly.q.push(['track_pageview']);
        this.countly.q.push(['track_sessions']);
        this.countly.q.push(['track_clicks']);
        this.countly.q.push(['track_scrolls']);
        this.countly.init();
    };

    trackPageView = (pageName: string) => {
        this.countly.q.push(['track_pageview', pageName]);
    };

    setUserProperty = (key: string, value: string) => {
        this.countly.q.push(['userData.set', key, value]);
    };

    trackEvent = (eventName: string, segmentName?: string, eventValue?: string) => {
        let segName = segmentName ? segmentName : '';
        let segValue = eventValue ? eventValue : '';
        this.countly.q.push([
            'add_event',
            {
                key: eventName,
                segmentation: {
                    [segName]: segValue,
                },
            },
        ]);
    };

    trackEventV2 = (eventName: string, extraInfo: {[index: string]: string}) => {
        this.countly.q.push([
            'add_event',
            {
                key: eventName,
                segmentation: {...extraInfo},
            },
        ]);
    };

    init = () => {};
}

const Analytics = new AnalyticsHelper();
export default Analytics;
