import React from "react";
import createStyles from "@material-ui/styles/createStyles";
import { withStyles } from "@material-ui/styles";
import { Checkbox as MUICheckbox } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import RadioButtonUnchecked from "@material-ui/icons/Lens";
import { colors } from "../theme";

const styles = createStyles({
  root: {
    width: 24,
    height: 24,
    color: colors.white,
    backgroundColor: colors.night,
    "&$checked": {
      color: colors.white
    }
  },
  checked: {
    backgroundColor: colors.night
  }
});

interface CheckBoxProps {
  classes: any;
  onChange: (event: object, checked: boolean) => void;
}

const Button: React.FC<CheckBoxProps> = ({ classes, ...props }) => {
  return (
    <MUICheckbox
      onChange={props.onChange}
      classes={classes}
      icon={<RadioButtonUnchecked />}
      checkedIcon={<CheckCircle />}
    />
  );
};

export default withStyles(styles)(Button);
