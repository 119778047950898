import './fonts.css';
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
const xs: Breakpoint = 'xs';
const sm: Breakpoint = 'sm';
const breakPoints: Breakpoint[] = [xs, sm];

export const colors = {
    white: '#FFFFFF',
    night: 'rgb(16,11,87)',
    darkGrey: 'rgb(37,39,43)',
    slateGrey: 'rgb(95,99,104)',
    blue: 'rgb(77,122,255)',
    snow: '#fbfbfd',
};

let resetPasswordTheme = createMuiTheme({
    palette: {
        background: {
            default: '#fbfbfd',
        },
        error: {
            main: 'rgb(255,86,79)',
        },
    },
    typography: {
        fontFamily: ['Averta', 'Averta-Bold', 'Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
            fontSize: '2.65rem',
            color: colors.white,
            fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        h2: {
            fontSize: '1.5rem',
            color: colors.darkGrey,
            fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        subtitle1: {
            fontSize: '1rem',
            color: colors.slateGrey,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        subtitle2: {
            fontSize: '0.75rem',
            color: colors.slateGrey,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
    },
});

resetPasswordTheme = responsiveFontSizes(resetPasswordTheme, {
    breakpoints: breakPoints,
});
export default resetPasswordTheme;
