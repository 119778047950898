import React, {useState} from 'react';
import createStyles from '@material-ui/styles/createStyles';
import {makeStyles} from '@material-ui/styles';
import {InputAdornment, Input} from '@material-ui/core';
import Button from './Button';
import LockImage from '../assets/image/ico-lock.svg';
import {colors} from '../theme-resetpassword';
import clsx from 'clsx';

interface PasswordInputProps {
    error: boolean;
    placeholder?: string;
    onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            width: 312,
            height: 56,
            borderRadius: 28,
            backgroundColor: colors.white,
            border: '1px',
            borderStyle: 'solid',
            borderColor: '#eaedf2',
            fontSize: 16,
            paddingLeft: 8,
            paddingTop: 0,
            paddingRight: 11,
            paddingBottom: 0,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            textTransform: 'none',
            color: colors.darkGrey,
        },
        error: {
            borderColor: 'rgb(255,86,79)',
        },
        button: {
            width: 80,
            height: 40,
        },
        buttonHide: {
            backgroundColor: colors.snow,
        },
        buttonShow: {
            backgroundColor: colors.blue,
        },

        hoverHide: {
            '&:hover, &.Mui-focusVisible': {backgroundColor: colors.blue},
        },
        hoverShow: {
            '&:hover, &.Mui-focusVisible': {backgroundColor: colors.snow},
        },
        buttonText: {
            textTransform: 'none',
            fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            fontSize: 16,
        },
        buttonTextHide: {
            color: colors.blue,
        },
        buttonTextShow: {
            color: '#fbfbfc',
        },
        lock: {
            width: 14,
            height: 20,
        },
    }),
);

const PasswordInputV2: React.FC<PasswordInputProps> = ({...props}) => {
    const styles = useStyles(props);
    const [showPassword, setShowPassword] = useState(false);

    const renderInputAdornmentStart = () => (
        <InputAdornment position="start">
            <img className={styles.lock} src={LockImage} alt="" />
        </InputAdornment>
    );

    const renderInputAdornmentEnd = () => (
        <InputAdornment position="end">
            <Button
                classes={{
                    root: showPassword ? styles.hoverHide : styles.hoverShow,
                    containedPrimary: showPassword
                        ? clsx(styles.button, styles.buttonShow)
                        : clsx(styles.button, styles.buttonHide),
                    label: showPassword
                        ? clsx(styles.buttonText, styles.buttonTextShow)
                        : clsx(styles.buttonText, styles.buttonTextHide),
                }}
                text={showPassword ? 'Hide' : 'Show'}
                onClick={() => {
                    setShowPassword(!showPassword);
                }}
            />
        </InputAdornment>
    );

    return (
        <Input
            placeholder={props.placeholder}
            type={showPassword ? 'text' : 'password'}
            disableUnderline={true}
            startAdornment={renderInputAdornmentStart()}
            endAdornment={renderInputAdornmentEnd()}
            className={props.error ? clsx(styles.root, styles.error) : styles.root}
            {...props}
        />
    );
};

export default PasswordInputV2;
