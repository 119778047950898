import React from 'react';
import Logo from '../assets/image/ico-logo.svg';
import {createStyles, withStyles, Grid} from '@material-ui/core';

const styles = createStyles({
    root: {
        display: 'flex',
        marginTop: '6%',
    },
    container: {
        display: 'flex',
        marginLeft: '4%',
    },
    logo: {
        width: 32,
    },
});

interface HeaderProps {
    blue?: boolean;
    classes: {
        container: string;
        logo: string;
        root: string;
    };
}

const SetNewPasswordHeader: React.FC<HeaderProps> = ({classes, blue}) => {
    return (
        <Grid direction="row" className={classes.root} container>
            <Grid item xs={1} className={classes.container}>
                <img className={classes.logo} src={Logo} alt="Logo" />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(SetNewPasswordHeader);
