import React from "react";
import { Typography } from "@material-ui/core";

const NotFound: React.FC = () => {
  return (
    <Typography align="center" variant="h1">
      Page Not Found!
    </Typography>
  );
};

export default NotFound;
