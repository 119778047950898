import React from 'react';

const AppContext = React.createContext({
    codeVerified: false,
    orgId: '',
    voucherCode: '',
    username: '',
    resetPasswordCode: '',
});

export default AppContext;
