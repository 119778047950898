import React from 'react';
import createStyles from '@material-ui/styles/createStyles';
import {makeStyles} from '@material-ui/styles';
import {Input} from '@material-ui/core';

interface TextInputProps {
    error: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            width: 311,
            height: 53,
            borderRadius: 15,
            backgroundColor: '#FFFFFF',
            border: (props: TextInputProps) => (props.error ? '2px' : '0px'),
            borderStyle: (props: TextInputProps) => (props.error ? 'solid' : 'none'),
            borderColor: (props: TextInputProps) => (props.error ? 'rgb(236,107,87)' : 'initial'),
            fontSize: 16,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 8,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            textTransform: 'none',
            color: 'rgb(16,11,87)',
        },
    }),
);

const TextInput: React.FC<TextInputProps> = props => {
    const styles = useStyles(props);
    return (
        <Input
            className={styles.root}
            disableUnderline={true}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            placeholder={props.placeholder}
            {...props}
        />
    );
};

export default TextInput;
