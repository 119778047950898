import React from "react";
import createStyles from "@material-ui/styles/createStyles";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import DateInput from "./DateInput";

interface DateOfBirthInputProps {
  error: boolean;
  onChange: (content: string, value: string) => void;
}

const userStyles = makeStyles(
  createStyles({
    item: {
      textAlign: "center"
    },
    divider: {
      display: "flex",
      width: 31,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      fontSize: "36px",
      color: "#FFFFFF"
    }
  })
);

const DateOfBirthInput: React.FC<DateOfBirthInputProps> = props => {
  const styles = userStyles(props);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const content = event.currentTarget.getAttribute("content");
    if (content) {
      props.onChange(content, event.currentTarget.value);
    }
  };
  return (
    <Grid item container justify="center" alignItems="center">
      <Grid item>
        <DateInput content="Date" onChange={onChange} />
      </Grid>
      <Grid item>
        <div className={styles.divider}>/</div>
      </Grid>
      <Grid item>
        <DateInput content="Month" onChange={onChange} />
      </Grid>
      <Grid item>
        <div className={styles.divider}>/</div>
      </Grid>
      <Grid item>
        <DateInput content="Year" error={false} onChange={onChange} />
      </Grid>
    </Grid>
  );
};

export default DateOfBirthInput;
