import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import {createStyles, withStyles, Grid} from '@material-ui/core';
import {withRouter, RouteComponentProps} from 'react-router';
import SetNewPasswordImage from '../assets/image/img-reset-password.svg';
import CustomerServiceFooter from './CustomerServiceFooter';
import {colors} from '../theme-resetpassword';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import UAParser from 'ua-parser-js';

const styles = createStyles({
    gridContainer: {
        marginBottom: '6%',
    },
    gridItem: {
        marginBottom: 16,
        alignContent: 'center',
        alignItems: 'center',
    },
    hint: {
        width: 308,
        marginTop: 16,
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        height: 89,
    },

    progress: {
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    title: {
        marginBottom: 25,
    },

    row: {
        marginBottom: 8,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 96,
        marginBottom: 20,
    },
    button: {
        width: 190,
        height: 56,
        borderRadius: 28,
        backgroundColor: colors.blue,
    },
    buttonText: {
        color: '#fbfbfc',
        textTransform: 'none',
        fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        fontSize: 16,
    },

    buttonHover: {
        '&:hover, &.Mui-focusVisible': {backgroundColor: colors.blue},
    },
});

interface SetNewPasswordResultProps extends RouteComponentProps {
    classes: {
        buttonContainer: string;
        gridContainer: string;
        gridItem: string;
        hint: string;
        title: string;
        image: string;
        progress: string;
        row: string;
        button: string;
        buttonText: string;
        buttonHover: string;
    };
}

const SetNewPasswordResult: React.FC<SetNewPasswordResultProps> = ({history, classes, location}) => {
    const ua = UAParser(window.navigator.userAgent);
    console.log(ua);
    const osType = ua.os.name === 'iOS' ? 'ios' : 'android';
    const smartBannerSettings = {
        price: {ios: 'GET', android: 'GET'},
    };
    return (
        <Fragment>
            <Grid direction="column" container justify="center" alignItems="center">
                <Grid item className={classes.gridItem}>
                    <img className={classes.image} src={SetNewPasswordImage} alt="Set new password" />
                </Grid>

                <Grid item className={classes.title}>
                    <Typography variant="h2" align="center">
                        Password updated
                    </Typography>
                </Grid>

                <Grid item className={classes.row}>
                    <div className={classes.hint}>
                        <Typography variant="subtitle1" color="initial">
                            {
                                'Your password has been updated and a confirmation email has been sent to your mail box. Sign in with your new password.'
                            }
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
                {osType !== 'ios' ? (
                    <SmartBanner
                        title={'Mentemia'}
                        position={'bottom'}
                        force={osType}
                        price={smartBannerSettings.price}
                        daysHidden={0}
                    />
                ) : null}
            </div>

            <CustomerServiceFooter />
        </Fragment>
    );
};

export default withRouter(withStyles(styles)(SetNewPasswordResult));
