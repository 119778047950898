export const terms_data = {
    terms_url: 'https://mentemia.com/mentemia-terms-of-use/',
    terms_link_text: 'Terms of Use',
    terms_text: 'I have read and accept the',
};

export const contactus_data = {
    contactus_url: 'https://mentemia.com/mentemia-customer-service/',
    contactus_link_text: 'Contact Support',
    contactus_text: 'Having Trouble?',
    customer_service_text: 'Customer Service',
};

export const store_urls = {
    apple: 'https://apps.apple.com/nz/app/mentemia/id1462692772?mt=8',
    google: 'https://play.google.com/store/apps/details?id=com.mentemia.companionapp',
};
