import React from 'react';
import {Router, Switch} from 'react-router';
import AppRoute from './AppRoute';
import NotFound from './components/NotFound';
import Register from './components/Register';
import Confirmation from './components/Confirmation';
import TWG from './components/TWG';
import ErrorPage from './components/Error';
import history from './history';
import mainLayout from './MainLayout';
import resetPasswordLayout from './ResetPasswordLayout';
import Code from './components/Code';
import './App.css';
import 'react-smartbanner/dist/main.css';
import Home from './components/Home';
import SetNewPassword from './components/SetNewPassword';
import SetNewPasswordResult from './components/SetNewPasswordResult';

const App = () => {
    return (
        <Router history={history}>
            <Switch>
                <AppRoute exact path="/" component={Home} layout={mainLayout} />
                <AppRoute exact path="/code" component={Code} layout={mainLayout} />
                <AppRoute exact path="/error" component={ErrorPage} layout={mainLayout} />
                <AppRoute exact path="/register/twg" component={TWG} layout={mainLayout} />
                <AppRoute exact path="/register" component={Register} layout={mainLayout} />
                <AppRoute exact path="/confirmation" component={Confirmation} layout={mainLayout} />
                <AppRoute exact path="/setnewpassword" component={SetNewPassword} layout={resetPasswordLayout} />
                <AppRoute
                    exact
                    path="/setnewpasswordresult"
                    component={SetNewPasswordResult}
                    layout={resetPasswordLayout}
                />
                <AppRoute exact path="*" component={NotFound} layout={mainLayout} />
            </Switch>
        </Router>
    );
};

export default App;
