import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import './App.css';
import Header from './components/Header';

import theme from './theme';
import {Grid, makeStyles, createStyles} from '@material-ui/core';

const userStyles = makeStyles(
    createStyles({
        header: {
            marginTop: '7%',
            marginBottom: '10%',
        },
    }),
);

export default ({children}: any) => {
    const styles = userStyles();
    return (
        <div>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Grid container>
                    <Grid item sm={4} xs={12}>
                        <div className={styles.header}>
                            <Header blue={false} />
                        </div>
                    </Grid>
                </Grid>
                <Container component="main" maxWidth="sm">
                    <Grid>{children}</Grid>
                </Container>
            </MuiThemeProvider>
        </div>
    );
};
