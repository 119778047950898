import React, {Fragment, useState, useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import Feedback from './Feedback';
import {createStyles, withStyles, Grid} from '@material-ui/core';
import Button from './Button';
import TextInput from './TextInput';
import {withRouter, RouteComponentProps} from 'react-router';
import AppContext from '../AppContext';
import {MentemiaService} from '../services/Api';
import Footer from './Footer';
import DateOfBirthInput from './DOBInput';
import Analytics from '../AnalyticsHelper';

const styles = createStyles({
    div: {},
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '9%',
    },
    container: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 311,
        marginBottom: '3%',
    },
    gridContainer: {
        margin: 0,
    },
    gridItem: {
        marginTop: '9%',
    },
});

interface TWGProps extends RouteComponentProps {
    classes: {
        div: string;
        buttonContainer: string;
        gridContainer: string;
        gridItem: string;
        container: string;
    };
}

const TWG: React.FC<TWGProps> = ({history, classes}) => {
    const [status, setStatus] = useState('waiting');
    const [employeeCode, setEmployeeCode] = useState('');
    const [date, setDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const appContext = useContext(AppContext);

    const onEmployeeCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmployeeCode(event.currentTarget.value);
    };

    const onDateChange = (content: string, value: string) => {
        switch (content) {
            case 'Date':
                setDate(value);
                break;
            case 'Month':
                setMonth(value);
                break;
            case 'Year':
                setYear(value);
                break;
        }
    };

    const isNumberKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    const formatDOB = (date: string, month: string, year: string) => {
        if (date.length === 1) {
            date = '0' + date;
        }
        if (month.length === 1) {
            month = '0' + month;
        }
        return year + month + date;
    };

    const verifyCode = async () => {
        const codeToVerify = 'TWG_' + employeeCode + '_' + formatDOB(date, month, year);
        Analytics.trackEvent('TWGVerificationClicked', 'code', codeToVerify);
        console.log(codeToVerify);
        const response = await MentemiaService.getInstance().validateVoucherCode({
            voucherCode: codeToVerify,
        });
        if (response.status !== 200) {
            setStatus('error');
        } else {
            appContext.orgId = response.orgId!;
            appContext.voucherCode = codeToVerify;
            appContext.codeVerified = true;
            history.replace('/register');
        }
    };

    return (
        <Fragment>
            <div>
                <Typography variant="h1" align="center">
                    Register for access
                </Typography>
            </div>
            <div>
                <Feedback status={status} />
            </div>
            <div className={classes.container}>
                <Typography variant="subtitle2" color={status === 'error' ? 'error' : 'initial'}>
                    Please enter your employee code and date of birth to get access.
                </Typography>
                <Grid container justify="center" className={classes.gridContainer}>
                    <Grid item xs={12} className={classes.gridItem}>
                        <TextInput
                            placeholder="Your employee number (six digits)"
                            error={false}
                            onChange={onEmployeeCodeChange}
                            onKeyPress={isNumberKey}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <DateOfBirthInput onChange={onDateChange} error={false} />
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button text={'Register'} onClick={verifyCode} />
                </div>
            </div>

            <Footer />
        </Fragment>
    );
};

export default withRouter(withStyles(styles)(TWG));
