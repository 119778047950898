import React, {useState} from 'react';
import createStyles from '@material-ui/styles/createStyles';
import {withStyles} from '@material-ui/styles';
import {InputBase} from '@material-ui/core';

const styles = createStyles({
    input: {
        width: 60,
        height: 60,
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        border: '0px',
        fontSize: 28,
        padding: 0,
        fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        textTransform: 'capitalize',
        textAlign: 'center',
        color: 'rgb(16,11,87)',
    },
});

interface CodeInputProps {
    classes: any;
    key: string;
    id: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const CodeInput = React.forwardRef((props: CodeInputProps, ref) => {
    const {key, id, classes, onKeyDown, onChange: onInputChange, ...rest} = props;
    const [value, setValue] = useState('_');
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newValue = event.currentTarget.value;
        setValue(newValue);
        onInputChange(event);
    };
    const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let currentValue = event.currentTarget.value;
        if (currentValue === '_') {
            setValue('');
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newValue = event.currentTarget.value;
        if (!newValue) {
            setValue('_');
        }
    };

    return (
        <InputBase
            inputProps={{
                id,
                maxLength: 1,
                ref,
                onKeyDown,
                onFocus,
                onBlur,
                onChange,
            }}
            classes={classes}
            value={value}
            {...rest}
        />
    );
});

export default withStyles(styles)(CodeInput);
