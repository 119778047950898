import React, {Fragment, useContext, useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {createStyles, withStyles, Grid, CircularProgress} from '@material-ui/core';
import Button from './Button';
import AppContext from '../AppContext';
import {MentemiaService} from '../services/Api';
import {withRouter, RouteComponentProps} from 'react-router';
import Analytics from '../AnalyticsHelper';
import SetNewPasswordImage from '../assets/image/img-reset-password.svg';
import PasswordInputV2 from './PasswordInputV2';
import CustomerServiceFooter from './CustomerServiceFooter';
import {colors} from '../theme-resetpassword';
import queryString from 'query-string';

const styles = createStyles({
    gridContainer: {
        marginBottom: '6%',
    },
    gridItem: {
        marginBottom: 16,
        alignContent: 'center',
        alignItems: 'center',
    },
    hint: {
        width: 308,
        marginTop: 16,
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        height: 89,
    },

    progress: {
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    title: {
        marginBottom: 25,
    },

    row: {
        marginBottom: 8,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 96,
        marginBottom: 20,
    },
    button: {
        width: 190,
        height: 56,
        borderRadius: 28,
        backgroundColor: colors.blue,
    },
    buttonText: {
        color: '#fbfbfc',
        textTransform: 'none',
        fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        fontSize: 16,
    },

    buttonHover: {
        '&:hover, &.Mui-focusVisible': {backgroundColor: colors.blue},
    },
});

interface SetNewPasswordProps extends RouteComponentProps {
    classes: {
        buttonContainer: string;
        gridContainer: string;
        gridItem: string;
        hint: string;
        title: string;
        image: string;
        progress: string;
        row: string;
        button: string;
        buttonText: string;
        buttonHover: string;
    };
}
const SetNewPassword: React.FC<SetNewPasswordProps> = ({history, classes, location}) => {
    const appContext = useContext(AppContext);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmationError, setConfirmationError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [loading, setLoading] = useState(true);

    const validatePassword = (password?: string): boolean => {
        if (!password) {
            return false;
        }
        const regexpPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        return regexpPassword.test(password!);
    };

    const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    };

    const handlePasswordConfirmInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmedPassword(event.currentTarget.value);
    };

    const handleSetNewPassword = async () => {
        Analytics.trackEvent('onSetNewPasswordClicked');

        const passwordValidationResult = validatePassword(password);
        const passwordConfirmationResult = password === confirmedPassword;

        setPasswordError(!passwordValidationResult);
        setConfirmationError(!passwordConfirmationResult);

        if (
            passwordValidationResult &&
            passwordConfirmationResult &&
            appContext.username &&
            appContext.resetPasswordCode
        ) {
            const response = await MentemiaService.getInstance().setNewPassword({
                username: appContext.username,
                password: password,
                code: appContext.resetPasswordCode,
            });

            if (response.status === 200) {
                Analytics.trackEvent('onSetNewPasswordCompleted');
                history.replace('/setNewPasswordResult');
            } else {
                setApiError(true);
            }
        }
    };

    const getErrorMessage = () => {
        if (passwordError) {
            return 'Your password must be at least 6 characters long and a mix of numbers and letters in upper and lowercase.';
        }
        if (confirmationError) {
            return 'Passwords don’t match. Please check and try again.';
        }
        if (apiError) {
            return 'The link is invalid or has expired. Please check and try again.';
        }
    };

    useEffect(() => {
        const parseLink = async (link: string) => {
            console.log('link to parse: ' + link);
            const resetPasswordCode = queryString.parse(link).code as string;
            const username = queryString.parse(link).email as string;
            if (resetPasswordCode && username) {
                appContext.username = username;
                appContext.resetPasswordCode = resetPasswordCode;
            } else {
                history.replace('/error');
            }
            setLoading(false);
        };

        parseLink(location.search);
    }, []);

    const renderSetNewPasswordInputs = () => {
        return (
            <Fragment>
                <Grid direction="column" container justify="center" alignItems="center">
                    <Grid item className={classes.gridItem}>
                        <img className={classes.image} src={SetNewPasswordImage} alt="Set new password" />
                    </Grid>

                    <Grid item className={classes.title}>
                        <Typography variant="h2" align="center">
                            Set Your New Password
                        </Typography>
                    </Grid>

                    <Grid item className={classes.row}>
                        <PasswordInputV2
                            onChange={handlePasswordInput}
                            placeholder={'Password'}
                            error={passwordError || confirmationError}
                        />
                    </Grid>
                    <Grid item>
                        <PasswordInputV2
                            onChange={handlePasswordConfirmInput}
                            placeholder={'Confirm Password'}
                            error={passwordError || confirmationError}
                        />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <div className={classes.hint}>
                            <Typography
                                variant="subtitle2"
                                color={passwordError || confirmationError || apiError ? 'error' : 'initial'}>
                                {getErrorMessage()}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button
                        classes={{
                            root: classes.buttonHover,
                            containedPrimary: classes.button,
                            label: classes.buttonText,
                        }}
                        text={'Set New Password'}
                        onClick={handleSetNewPassword}
                    />
                </div>

                <CustomerServiceFooter />
            </Fragment>
        );
    };

    const renderSpinner = () => {
        return (
            <div className={classes.progress}>
                <CircularProgress />
            </div>
        );
    };

    return loading ? renderSpinner() : renderSetNewPasswordInputs();
};

export default withRouter(withStyles(styles)(SetNewPassword));
