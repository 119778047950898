import React from "react";
import feedbackWaiting from "../assets/image/feedback-waiting.gif";
import feedbackGood from "../assets/image/feedback-good.gif";
import feedbackError from "../assets/image/feedback-error.gif";
import createStyles from "@material-ui/styles/createStyles";
import { withStyles } from "@material-ui/styles";

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  img: {
    height: 100
  }
});

interface FeedbackProps {
  status?: string;
  classes: {
    root: string;
    img: string;
  };
}

const Feedback: React.FC<FeedbackProps> = ({ status, classes }) => {
  let image = feedbackWaiting;
  switch (status) {
    case "good":
      image = feedbackGood;
      break;
    case "error":
      image = feedbackError;
      break;
    default:
      break;
  }
  return (
    <div className={classes.root}>
      <img className={classes.img} src={image} alt="" />
    </div>
  );
};

export default withStyles(styles)(Feedback);
