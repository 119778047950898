import React, {Fragment, useContext, useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Feedback from './Feedback';
import {createStyles, withStyles, Grid} from '@material-ui/core';
import Button from './Button';
import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import Terms from './Terms';
import AppContext from '../AppContext';
import {MentemiaService} from '../services/Api';
import {withRouter, RouteComponentProps} from 'react-router';
import Footer from './Footer';
import Analytics from '../AnalyticsHelper';
import Helper from '../Helper';

const styles = createStyles({
    div: {
        marginBottom: '3%',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridContainer: {
        marginBottom: '6%',
    },
    gridItem: {
        marginBottom: '3%',
        alignContent: 'center',
        alignItems: 'center',
    },
    hint: {
        width: 308,
        marginTop: '3%',
    },
    progress: {
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

interface RegisterProps extends RouteComponentProps {
    classes: {
        div: string;
        buttonContainer: string;
        gridContainer: string;
        gridItem: string;
        hint: string;
        progress: string;
    };
}

interface RegisterUIState {
    usernameError: boolean;
    passwordError: boolean;
    termsError: boolean;
    feedbackStatus: string;
}

const Register: React.FC<RegisterProps> = ({history, classes, location}) => {
    const initalUIState: RegisterUIState = {
        usernameError: false,
        passwordError: false,
        termsError: false,
        feedbackStatus: 'waiting',
    };
    const appContext = useContext(AppContext);
    const [uiState, setUiState] = useState(initalUIState);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [termsAcceptedStatus, setTermsAcceptedStatus] = useState(false);

    useEffect(() => {
        if (!appContext.codeVerified) {
            history.replace('/');
        }
    });

    const validatePassword = (password?: string): boolean => {
        if (!password) {
            return false;
        }
        const regexpPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        return regexpPassword.test(password!);
    };

    const validateEmail = (email?: string): boolean => {
        return Helper.ValidateEmail(email);
    };

    const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    };

    const handleUsernameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value);
    };

    const handleAcceptTerms = (event: object, checked: boolean) => {
        setTermsAcceptedStatus(checked);
    };

    const handleRegister = async () => {
        Analytics.trackEvent('RegisterClicked');
        const usernameToRegister = username.trim();
        const usernameValidationResult = validateEmail(usernameToRegister);
        const passwordValidationResult = validatePassword(password);
        const validationSuccess = usernameValidationResult && passwordValidationResult && termsAcceptedStatus;

        setUiState({
            usernameError: !usernameValidationResult,
            passwordError: !passwordValidationResult,
            termsError: !termsAcceptedStatus,
            feedbackStatus: validationSuccess ? 'good' : 'error',
        });

        if (!validationSuccess) {
            Analytics.trackEventV2('RegisterUIValidationFailed', {
                code: appContext.voucherCode,
                email: Helper.GetMaskedEmail(usernameToRegister),
            });
        }

        if (validationSuccess && appContext.codeVerified) {
            const response = await MentemiaService.getInstance().registerUser({
                username: usernameToRegister,
                password,
                orgId: appContext.orgId,
                code: appContext.voucherCode,
            });

            if (response.status === 200) {
                Analytics.trackEvent('RegisterCompleted', 'code', appContext.voucherCode);
                history.replace('/confirmation');
            } else {
                setUiState({
                    usernameError: false,
                    passwordError: false,
                    termsError: false,
                    feedbackStatus: 'error',
                });
            }
        }
    };

    const renderRegisterInputs = () => {
        return (
            <Fragment>
                {console.log('render called!')}
                <div className={classes.div}>
                    <Typography variant="h1" align="center">
                        Create your account
                    </Typography>
                </div>

                <div className={classes.div}>
                    <Feedback status={uiState.feedbackStatus} />
                </div>
                <Grid className={classes.gridContainer} container justify="center">
                    <Grid item className={classes.gridItem}>
                        <TextInput onChange={handleUsernameInput} error={uiState.usernameError} placeholder={'Email'} />
                    </Grid>
                    <Grid item>
                        <PasswordInput onChange={handlePasswordInput} error={uiState.passwordError} />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <div className={classes.hint}>
                            <Typography variant="subtitle2" color={uiState.passwordError ? 'error' : 'initial'}>
                                Your password must be at least 6 characters long and a mix of numbers and letters in
                                upper and lowercase.
                            </Typography>
                            <Terms onChange={handleAcceptTerms} error={uiState.termsError} />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button text={'Register'} onClick={handleRegister} />
                </div>
                <Footer />
            </Fragment>
        );
    };

    return renderRegisterInputs();
};

export default withRouter(withStyles(styles)(Register));
