import React, {useContext, useEffect} from 'react';
import {createStyles, withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppContext from '../AppContext';
import {MentemiaService} from '../services/Api';
import {withRouter, RouteComponentProps} from 'react-router';
import queryString from 'query-string';
import Analytics from '../AnalyticsHelper';

const styles = createStyles({
    progress: {
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

interface HomeProps extends RouteComponentProps {
    classes: {
        progress: string;
    };
}

const Home: React.FC<HomeProps> = ({history, classes, location}) => {
    const appContext = useContext(AppContext);

    const verifyLink = async (link: string) => {
        console.log('link to verify: ' + link);
        const codeToVerify = queryString.parse(link).code as string;
        Analytics.trackEvent('onRegistrationLinkClicked', 'value', link);
        Analytics.setUserProperty('RegCode', codeToVerify);
        if (codeToVerify) {
            const response = await MentemiaService.getInstance().validateVoucherCode({
                voucherCode: codeToVerify,
            });
            if (response.status === 200) {
                appContext.orgId = response.orgId!;
                appContext.voucherCode = codeToVerify;
                appContext.codeVerified = true;
                history.replace('/register');
                return;
            } else {
                history.replace('/error');
                return;
            }
        }
        history.replace('/code');
    };

    useEffect(() => {
        if (!appContext.codeVerified) {
            verifyLink(location.search);
        }
    });

    const renderSpinner = () => {
        return (
            <div className={classes.progress}>
                <CircularProgress />
            </div>
        );
    };

    return renderSpinner();
};

export default withRouter(withStyles(styles)(Home));
