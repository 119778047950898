import React, {useState} from 'react';
import createStyles from '@material-ui/styles/createStyles';
import {makeStyles} from '@material-ui/styles';
import {InputBase} from '@material-ui/core';

interface DateInputProps {
    error?: boolean;
    content: 'Date' | 'Month' | 'Year';
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}
const userStyles = makeStyles(
    createStyles({
        root: {
            width: (props: DateInputProps) => (props.content === 'Year' ? 124 : 62),
            height: 53,
            borderRadius: 15,
            backgroundColor: '#FFFFFF',
            border: (props: DateInputProps) => (props.error ? '2px' : '0px'),
            borderStyle: (props: DateInputProps) => (props.error ? 'solid' : 'none'),
            borderColor: (props: DateInputProps) => (props.error ? 'rgb(236,107,87)' : 'initial'),
            fontSize: 16,
            paddingTop: 0,
            paddingBottom: 0,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            textTransform: 'none',
            color: 'rgb(16,11,87)',
            textAlign: 'center',
        },
    }),
);

const DateInput: React.FC<DateInputProps> = props => {
    const styles = userStyles(props);
    const [value, setValue] = useState('');
    const getPlaceHolder = (props: DateInputProps) => {
        switch (props.content) {
            case 'Date':
                return 'DD';
            case 'Month':
                return 'MM';
            case 'Year':
                return 'YYYY';
        }
    };

    const getMaxLength = (props: DateInputProps) => {
        switch (props.content) {
            case 'Date':
                return 2;
            case 'Month':
                return 2;
            case 'Year':
                return 4;
        }
    };

    const tryParseNumber = (str: any, defaultValue: number = 0): number => {
        let retValue = defaultValue;
        if (!str) {
            return 0;
        }
        if (!isNaN(str)) {
            retValue = parseInt(str, 10);
        }
        return retValue;
    };

    const isValid = (value: number) => {
        if (props.content === 'Date') {
            return value >= 0 && value <= 31;
        }
        if (props.content === 'Month') {
            return value >= 0 && value <= 12;
        }
        if (props.content === 'Year') {
            return value >= 0 && value < 2020;
        }
        return false;
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let strValue = event.currentTarget.value;
        let numValue = tryParseNumber(strValue, -1);
        if (isValid(numValue)) {
            setValue(numValue + '');
            props.onChange(event);
        } else {
            event.currentTarget.value = value + '';
        }
    };
    return (
        <InputBase
            type="text"
            inputProps={{
                className: styles.root,
                onChange: onChange,
                content: props.content,
                maxLength: getMaxLength(props),
            }}
            placeholder={getPlaceHolder(props)}
            {...props}
        />
    );
};

export default DateInput;
