import './fonts.css';
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
const xs: Breakpoint = 'xs';
const sm: Breakpoint = 'sm';
const breakPoints: Breakpoint[] = [xs, sm];

export const colors = {
    white: '#FFFFFF',
    night: 'rgb(16,11,87)',
};

let theme = createMuiTheme({
    palette: {
        background: {
            default: '#100b57',
        },
        error: {
            main: 'rgb(236,107,87)',
        },
    },
    typography: {
        fontFamily: ['Averta', 'Averta-Bold', 'Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
            fontSize: '2.65rem',
            color: colors.white,
            fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        h2: {
            fontSize: '1.5rem',
            color: colors.white,
            fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        subtitle1: {
            fontSize: '1rem',
            color: colors.white,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        subtitle2: {
            fontSize: '0.75rem',
            color: colors.white,
            fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
    },
});

theme = responsiveFontSizes(theme, {breakpoints: breakPoints});
export default theme;
