class HelperClass {
    ValidateEmail = (email?: string) => {
        if (!email) return false;
        const regexpEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        return regexpEmail.test(email!);
    };
    GetMaskedEmail = (email: string) => {
        if (!this.ValidateEmail(email)) return email;
        const index = email.indexOf('@');
        var prefix = email.substring(0, index);
        var postfix = email.substring(index);
        var mask = prefix
            .split('')
            .map(function(o, i) {
                if (i <= 1 || i == index - 1) {
                    return o;
                } else {
                    return '*';
                }
            })
            .join('');

        const maskedEmail = mask + postfix;
        console.log(maskedEmail);
        return maskedEmail;
    };
}

const Helper = new HelperClass();
export default Helper;
