import React, {Fragment, useRef, useState, useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import Feedback from './Feedback';
import {createStyles, withStyles, Grid} from '@material-ui/core';
import Button from './Button';
import CodeInput from './CodeInput';
import {withRouter, RouteComponentProps} from 'react-router';
import AppContext from '../AppContext';
import {MentemiaService} from '../services/Api';
import Footer from './Footer';

const styles = createStyles({
    div: {
        marginBottom: '3%',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridContainer: {
        marginBottom: '6%',
    },
});

interface CodeProps extends RouteComponentProps {
    classes: {
        div: string;
        buttonContainer: string;
        gridContainer: string;
    };
}

const Code: React.FC<CodeProps> = ({history, classes, location}) => {
    const codeLength = 4;
    const inputRefs: {[id: string]: any} = {};
    const voucherCode: string[] = [];

    const [status, setStatus] = useState('waiting');
    const appContext = useContext(AppContext);

    for (let i = 0; i < codeLength; i++) {
        inputRefs[i] = React.createRef();
        voucherCode.push('');
    }
    const refs = useRef(inputRefs);
    const code = useRef(voucherCode);

    const renderCodeInputs = () => {
        const codeInputElements = [];
        for (let index = 0; index < 4; index++) {
            codeInputElements.push(
                <Grid item key={'GridItem' + index}>
                    <CodeInput
                        key={'CodeInput_' + index}
                        id={index + ''}
                        ref={refs.current[index]}
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                    />
                </Grid>,
            );
        }
        return codeInputElements;
    };

    const changeFocus = (id: number, direction: string) => {
        if (direction.toLocaleLowerCase() === 'next') {
            if (id + 1 < codeLength) {
                refs.current[id + 1].current!.focus();
            }
        } else if (direction.toLocaleLowerCase() === 'prev') {
            if (id > 0) {
                refs.current[id - 1].current!.focus();
            }
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.nativeEvent.key === 'Backspace') {
            if (event.currentTarget.value === '') {
                changeFocus(parseInt(event.currentTarget.id), 'prev');
            }
        }
        return;
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const id = parseInt(event.currentTarget.id);
        const value = event.currentTarget.value;
        code.current[id] = value.toLocaleUpperCase();
        if (value !== '') {
            changeFocus(id, 'next');
        }
    };

    const verifyCode = async () => {
        console.log('Verify Code');
        const codeToVerify = code.current.join('');
        const response = await MentemiaService.getInstance().validateVoucherCode({
            voucherCode: codeToVerify,
        });
        if (response.status !== 200) {
            setStatus('error');
            setTimeout(() => {
                setStatus('waiting');
            }, 2000);
        } else {
            appContext.orgId = response.orgId!;
            appContext.voucherCode = codeToVerify;
            appContext.codeVerified = true;
            history.replace('/register');
        }
    };

    return (
        <Fragment>
            <div className={classes.div}>
                <Typography variant="h1" align="center">
                    Enter your code
                </Typography>
            </div>
            <div className={classes.div}>
                <Typography variant="subtitle1" align="center">
                    Let’s get you all set up with your new account.
                </Typography>
            </div>
            <div className={classes.div}>
                <Feedback status={status} />
            </div>
            <Grid className={classes.gridContainer} container justify="center" spacing={2}>
                {renderCodeInputs()}
            </Grid>
            <div className={classes.buttonContainer}>
                <Button text={'Verify code'} onClick={verifyCode} />
            </div>
            <Footer />
        </Fragment>
    );
};

export default withRouter(withStyles(styles)(Code));
