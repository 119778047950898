import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import LandingPage from './components/Landing';
import {Router, Switch, Route} from 'react-router-dom';
import App from './App';
import {createBrowserHistory} from 'history';
import * as serviceWorker from './serviceWorker';
import Analytics from './AnalyticsHelper';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
} else {
    console.log = () => {};
}
const history = createBrowserHistory();

history.listen(location => {
    Analytics.trackPageView(location.pathname + location.hash);
});
ReactDOM.render(
    <Router history={history}>
        <Switch>
            <Route exact path="/twg" component={LandingPage} />
            <Route exact path="*" component={App} />
        </Switch>
    </Router>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
