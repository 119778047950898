import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import './App.css';
import theme from './theme-resetpassword';
import SetNewPasswordHeader from './components/SetNewPasswordHeader';

export default ({children}: any) => {
    return (
        <div>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <SetNewPasswordHeader />
                <Container component="main" maxWidth="sm">
                    {children}
                </Container>
            </MuiThemeProvider>
        </div>
    );
};
